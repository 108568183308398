var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[(_vm.statusIcon)?_c('v-list-item-avatar',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":_vm.statusColor}},on),[_vm._v(_vm._s(_vm.statusIcon))])]}}],null,false,1583253966)},[_c('span',[_vm._v(_vm._s(_vm.statusTooltip))])])],1):_vm._e(),_c('v-list-item-content',{style:(_vm.item.removed || _vm.isDropped ? 'text-decoration:line-through' : '')},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.item.title)+" "),(_vm.item.credits)?_c('span',[_vm._v("("+_vm._s(_vm.item.credits)+" Credits)")]):_vm._e(),_c('br')]),(_vm.item.meets)?_c('v-list-item-subtitle',[_c('b',{staticStyle:{"vertical-align":"top"}},[_vm._v("Meets: ")]),_c('span',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(_vm.item.meets || _vm.getMeetString(_vm.meetingBase))}})]):_vm._e(),_c('v-list-item-subtitle',[_c('b',[_vm._v("Instructor:")]),_vm._v(" "+_vm._s(_vm.item.instructor.name || _vm.item.instructor)+" "),(_vm.item.instructorEmail)?_c('span',[_vm._v("("+_vm._s(_vm.item.instructorEmail)+")")]):_vm._e()])],1),(_vm.actions.length > 0)?_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-pencil")])],1)]}}],null,false,918322646)},[_c('v-list',_vm._l((_vm.actions),function(ref){
var action = ref.action;
var text = ref.text;
return _c('v-list-item',{key:action,on:{"click":function($event){return _vm.$emit(action)}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }