<template>
  <v-list-item>
    <v-list-item-avatar v-if="statusIcon">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="statusColor">{{ statusIcon }}</v-icon>
        </template>
        <span>{{ statusTooltip }}</span>
      </v-tooltip>
    </v-list-item-avatar>
    <v-list-item-content :style="item.removed || isDropped ? 'text-decoration:line-through' : ''">
      <v-list-item-title>
        {{ item.title }}
        <span v-if="item.credits">({{ item.credits }} Credits)</span><br/>
      </v-list-item-title>
      <v-list-item-subtitle v-if="item.meets">
          <b style="vertical-align:top">Meets: </b>
          <span v-html="item.meets || getMeetString(meetingBase)" style="display:inline-block"></span>
        </v-list-item-subtitle>
      <v-list-item-subtitle><b>Instructor:</b> {{ item.instructor.name || item.instructor }} <span v-if="item.instructorEmail">({{ item.instructorEmail }})</span></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="actions.length > 0">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-pencil</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ action, text } in actions" :key="action" @click="$emit(action)">
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    changes: {
      type: Array,
      required: true
    },
    isUpperClassman: {
      type: Boolean,
      default: false
    },
    canDrop: {
      type: Boolean,
      default: false
    },
    canChangeGradeMode: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)

    const change = computed(() => props.changes.filter(({ crn }) => crn === props.item.crn))
    const changeAction = computed(() => change.value.length === 0 ? false : change.value[change.value.length - 1].action)

    const actions = computed(() => {
      const arr = []
      if (change.value.length > 0) arr.push({ action: 'undo', text: 'Cancel ' + changeAction.value.substring(0, 1).toUpperCase() + changeAction.value.substring(1) })
      if (props.canDrop && (change.value.length === 0 || change.value[0].action === 'change')) arr.push({ action: 'drop', text: 'Drop Course' })
      if (props.canChangeGradeMode && (change.value.length === 0 || change.value[0].action !== 'change')) arr.push({ action: 'change', text: 'Change Grade Mode to ' + (props.item.gradeMode === 'grade' ? 'Pass/Fail' : 'Standard Grade') })
      return arr
    })

    const isDropped = computed(() => props.item.regStatus === 'DC' || props.item.regStatus === 'DD')

    const statusIcon = computed(() => {
      switch (changeAction.value) {
        case 'drop':
          return 'fas fa-minus-circle'
        case 'add':
          return 'fas fa-plus-circle'
        case 'change':
          return 'fas fa-info-circle'
      }
      return false
    })
    const statusColor = computed(() => {
      switch (changeAction.value) {
        case 'drop':
          return 'error'
        case 'add':
          return 'success'
        case 'change':
          return 'info'
      }
    })
    const statusTooltip = computed(() => {
      switch (changeAction.value) {
        case 'drop':
          return 'Course is to be dropped'
        case 'add':
          return 'Course is being added'
        case 'change':
          return 'Course grade mode being changed'
      }
    })

    const getMeetString = computed(() => props.item.meetingBase.map(
      ({ days, startTime, endTime, room, building }) => {
        let arr = [days]
        if (startTime != null && startTime !== '' && endTime != null && endTime !== '') arr.push(startTime + ' - ' + endTime)
        if (room != null && room !== '') arr.push(' in ' + room + (building != null && building !== '' ? ' (' + building + ')' : ''))
        return arr.join(' ')
      }).join('<br/>')
    )

    return {
      user,
      roles,
      change,
      changeAction,
      actions,
      isDropped,
      statusIcon,
      statusColor,
      statusTooltip,
      getMeetString
    }
  }
}
</script>
